import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './views/home/auth.guard';
import { NgModule } from '@angular/core';

const routes: Routes = [
    {
        path: "brand",
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuardService],
        loadChildren: () =>
            import("./views/brand/brand.module").then((m) => m.BrandModule)
    },
    {
        path: "marketplace",
        canActivate: [AuthGuardService],
        loadChildren: () =>
            import("./views/marketplace/marketplace.module").then((m) => m.MarketplaceModule)
    },
    {
        path: "",
        loadChildren: () =>
            import("./views/home/home.module").then((m) => m.HomeModule)
    },
    // { path: '**', redirectTo: "" }
];


@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
  })
  export class AppRoutingModule {
  
  }
  
