import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, UrlTree } from '@angular/router';
import { AuthService } from './auth.service';
import { MatDialog } from '@angular/material/dialog';
import { AuthDialogComponent } from './auth-dialog/auth-dialog.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private authService: AuthService, 
    private router: Router,
    private dialog: MatDialog
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let userType: any = route.url[0].path
    let result: any = await this.authService.isAuthenticated(userType)
    if(result){
      switch(userType){
        case "marketplace":
          if(result == "marketplace")
            return true
          else{
            this.router.navigate(['brand'])
            return false
          }
        case "brand":
          if(result == "brand")
            return true
          else{
            this.router.navigate(['marketplace'])
            return false
          }
      }
    }
    if (result) {
      return true;
    } else {
      this.dialog.open(AuthDialogComponent, {
        width: "50%",
        height: "auto",
        data: {postLoginpath: state.url}
      }).afterClosed().subscribe(result => {if(!result) this.router.navigate([''])})
      return false;
    }
  }
}
