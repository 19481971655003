import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {TranslateService} from "@ngx-translate/core";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'productdev-edge-angular';

  constructor(private translate: TranslateService) {
    this.translate.addLangs(['de', 'en', 'es', 'fr']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }

}
