// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const APP_VERSION = "3.3"

var env="test"
// var env="test2"
// var env="prod"
// var env="wip"
export const releaseTime = "16:30 Jan 3, 2024"

export const environment = {
  production: true
};

var backendUrl: String
var backendWsHostUrl: String
var frontendUrl: String
var rzpKey: String
if(env=="test"){
  backendWsHostUrl = "wss://backend-test.productdevedge.com"
  backendUrl = "https://backend-test.productdevedge.com/"
  frontendUrl = "https://test.productdevedge.com/"
  rzpKey = "rzp_test_Tmxcn76PsIFxU5"
}
else if(env=="test2"){
  backendWsHostUrl = "wss://dev.productdevedge.com"
  backendUrl = "https://dev.productdevedge.com/"
  frontendUrl = "https://test2.productdevedge.com/"
  rzpKey = "rzp_test_Tmxcn76PsIFxU5"
}
else{
  backendWsHostUrl = "wss://api.productdevedge.com"
  backendUrl = "https://api.productdevedge.com/"
  frontendUrl = "https://productdevedge.com/"
  rzpKey = "rzp_live_uJ7Lb3oNjVbtg8"
}

export const backend = backendUrl
export const frontend = frontendUrl
export const backendWsHost = backendWsHostUrl
export const rzp_key = rzpKey
export const wip = (env=="wip")
export const wipStatus = "updates on ingredient research"
export const ENV = env

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
