import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { InViewportAction } from 'ng-in-viewport';

@Component({
  selector: 'app-register-disclaimer-dialog',
  templateUrl: './register-disclaimer-dialog.component.html',
  styleUrl: './register-disclaimer-dialog.component.scss'
})
export class RegisterDisclaimerDialogComponent {

  isFinalPage: boolean;

  inViewportAction(event: InViewportAction){
    this.isFinalPage = event.visible
  }

}
