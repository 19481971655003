<div mat-dialog-title class="d-title">
    Reset account password
    <div class="flex-grow"></div>
    <button mat-icon-button (click)="closeDialog()" color="warn"><mat-icon>close</mat-icon></button>
</div>
<mat-dialog-content>
    <div class="col-12" *ngIf="!otpSent">
        <div class="input-field">
            <div class="input-wrapper">
                <input class="input" matInput placeholder="Business Email / Work Email *" [formControl]="emailControl" cdkFocusInitial>
                <mat-icon class="input-error" color="warn" *ngIf="emailControl.errors" [matTooltip]="getEmailError()">error</mat-icon>
            </div>
        </div>
        <div class="input-field register-as-field">
            <div class="input-wrapper register-as">
                <mat-select class="input" [formControl]="accountTypeControl" placeholder="Account type">
                    <mat-option value="brand">Product Development</mat-option>
                    <mat-option value="marketplace"> Ingredient Providers</mat-option>
                </mat-select>
                <mat-icon class="input-error" color="warn" *ngIf="accountTypeControl.errors?.required" matTooltip="Required">error</mat-icon>
            </div>
        </div>
        <div class="col-12 submit-wrapper">
            <button class="p-submit" [disabled]="emailControl.invalid || accountTypeControl.invalid || loading" (click)="requestOTP()">Send OTP<mat-spinner class="mx-2" diameter="24" *ngIf="loading"></mat-spinner></button>
        </div>
    </div>
    <div class="col-12" *ngIf="otpSent && !otpVerified">
        <div class="col-12">
            <div class="input-field">
                <div class="input-wrapper">
                    <input class="input" type="number" matInput placeholder="OTP" [formControl]="otpControl" cdkFocusInitial>
                    <mat-icon class="input-error" color="warn" *ngIf="otpControl.errors" [matTooltip]="getOTPError()">error</mat-icon>
                </div>
            </div>
            <div class="mt-4 flex">
                <div class="col-12 submit-wrapper">
                    <button class="p-submit" (click)="verifyOTP()" [disabled]="otpControl.invalid || loading">Verify OTP<mat-spinner class="mx-2" diameter="24" *ngIf="loading"></mat-spinner></button>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12" *ngIf="otpVerified">
        <div class="col-12">
            <div class="input-field">
                <div class="input-wrapper">
                    <input class="input" matInput placeholder="New Password" [formControl]="newPasswordControl" cdkFocusInitial>
                    <mat-icon class="input-error" color="warn" *ngIf="newPasswordControl.errors" [matTooltip]="getNewPasswordError()">error</mat-icon>
                </div>
            </div>
            <div class="input-field">
                <div class="input-wrapper">
                    <input class="input" matInput placeholder="Confirm Password" [formControl]="confirmPasswordControl">
                    <mat-icon class="input-error" color="warn" *ngIf="confirmPasswordControl.errors" [matTooltip]="getConfirmPasswordError()">error</mat-icon>
                </div>
            </div>
            <div class="mt-4 flex">
                <div class="col-12 submit-wrapper">
                    <button class="p-submit" (click)="changePassword()" [disabled]="newPasswordControl.invalid || confirmPasswordControl.invalid || loading">Change Password<mat-spinner class="mx-2" diameter="24" *ngIf="loading"></mat-spinner></button>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>