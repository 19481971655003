import { AbstractControl, ValidationErrors } from "@angular/forms";
import { Chemical } from "./app.models";

const prohib_domains: any = [
  "gmail.com",
  "yahoo.com",
  "hotmail.com",
  "aol.com",
  "hotmail.co.in",
  "yahoo.co.in",
  "live.com",
  "msn.com",
  "yahoo.co.uk",
  "rediffmail.com",
  "yandex.ru",
  "ymail.com",
  "outlook.com"
]

const emailRegex: any = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export function getChemicalDisplayName(chemical: Chemical){
	return chemical.chemical_name && chemical.chemical_name.length > 0 ?
      chemical.chemical_name.join(" / ") : chemical.inci && chemical.inci.length > 0 ?
        chemical.inci.join(" / ") : chemical.iupac_name && chemical.iupac_name.length > 0 ?
          chemical.iupac_name.join(" / ") : chemical.common_name && chemical.common_name.length > 0 ?
            chemical.common_name.join(" / ") : chemical.other_names ? chemical.other_names : "No chemical name"
}

export class MayaValidators{
  static phoneNo(control: AbstractControl): ValidationErrors{
    if(control.value && control.value.length>0){
      if (control.value.length == 10 && Number(control.value)) {
        return null
      } else {
        return {invalidPhone: true}
      }
    }
    return null
  }

  static email(control: AbstractControl): ValidationErrors { 
    if(control.value && !String(control.value).match(emailRegex))
      return {invalidEmail: true}
    return null;
  }

  static workEmail(control: AbstractControl): ValidationErrors{
    if(prohib_domains.some(domain => control.value.toLocaleLowerCase().includes(domain))){
      return {prohibited_domain: true}
    }
    return null
  }

  static number(control: AbstractControl): ValidationErrors{
    if(control.value && !String(control.value).match(/[0-9]+/gm)){
      return {number: true}
    }
    return null
  }

  static password(control: AbstractControl): ValidationErrors{
    if(!String(control.value).match(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/gm)){
      return {weak: true}
    }
    return null
  }
}

export function dateTo12am(date: Date){
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)
  date.setMilliseconds(0)
  return date
}