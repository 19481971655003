<div mat-dialog-content class="row" style="margin: 16px;">
    <h1 class="col-12" style="text-align: center; font-size: xxx-large; font-weight: bolder; line-height: 1;">{{"tnc.1" | translate}}</h1>
    <h4 class="col-12" style="margin: 4px 8px; font-size: large; font-weight: bold;">{{"tnc.2" | translate}}</h4>
    
    <p class="col-12" style="margin: 8px 16px;">{{"tnc.3" | translate}}</p>
    
    
    <h4 class="col-12" style="margin: 4px 8px; font-size: large; font-weight: bold;">{{"tnc.4" | translate}}</h4>
    
    <p class="col-12" style="margin: 8px 16px;">{{"tnc.5" | translate}}</p>
    <ul class="col-12 row" style="margin: 8px 32px;">
        <li class="col-12">{{"tnc.6" | translate}}</li>
        <li class="col-12">{{"tnc.7" | translate}}</li>
        <li class="col-12">{{"tnc.8" | translate}}</li>
    
    </ul>
       
    <p class="col-12" style="margin: 8px 16px;">{{"tnc.9" | translate}}</p>
    <ul class="col-12 row" style="margin: 8px 32px;">
        <li class="col-12">{{"tnc.10" | translate}}</li>
        <li class="col-12">{{"tnc.11" | translate}}</li>
        <li class="col-12">{{"tnc.12" | translate}}</li>
        <li class="col-12">{{"tnc.13" | translate}}</li>
        <li class="col-12">{{"tnc.14" | translate}}</li>
    </ul>
     
    <p class="col-12" style="margin: 8px 8px;">{{"tnc.15" | translate}}</p>
    
    <p class="col-12" style="margin: 8px 16px;">{{"tnc.16" | translate}}</p>
    <ul class="col-12 row" style="margin: 8px 32px;">
        <li class="col-12">{{"tnc.17" | translate}}</li> 
        <li class="col-12">{{"tnc.18" | translate}}</li> 
        <li class="col-12">{{"tnc.19" | translate}}</li> 
        <li class="col-12">{{"tnc.20" | translate}}</li> 
    </ul>
    
    
    <p class="col-12" style="margin: 8px 8px;">{{"tnc.21" | translate}}</p>
    
    <p class="col-12" style="margin: 8px 8px;">{{"tnc.22" | translate}}</p>
    
    <p class="col-12" style="margin: 8px 8px;">{{"tnc.23" | translate}}</p>
</div>
<mat-dialog-actions *ngIf="isDialogMode">
    <button mat-button mat-dialog-close>{{"tnc.close" | translate}}</button>
</mat-dialog-actions>