import { NgModule } from "@angular/core";
import { YesNoComponent } from "./yes-no/yes-no.component";
import { MaterialCommonModule } from "../../material-common.module"; // Corrected import path
import { SendErrorDetailsDialogComponent } from "./send-error-details-dialog/send-error-details-dialog.component";
import { GetTextDialogComponent } from "./get-text-dialog/get-text-dialog.component";
import { ShowTextDialogComponent } from "./show-text-dialog/show-text-dialog.component";
import { GetDualTextDialogComponent } from "./get-dual-text-dialog/get-dual-text-dialog.component";
import { SearchChemicalForSuppliersComponent } from "./search-chemical-for-suppliers/search-chemical-for-suppliers.component";
import { GetValueDialogComponent } from "./get-value-dialog/get-value-dialog.component";
import { ViewSupplierProfilePublicDialogComponent } from "./view-supplier-profile-public-dialog/view-supplier-profile-public-dialog.component";
import { ViewSupplierReportPublicDialogComponent } from "./view-supplier-report-public-dialog/view-supplier-report-public-dialog.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { CommonPartialsModule } from "../partials/common-partials.module";
import { MultipleChoiceQuestionsDialogComponent } from './multiple-choice-questions-dialog/multiple-choice-questions-dialog.component';

@NgModule({
    imports: [
        MaterialCommonModule,
        NgxSpinnerModule,
        CommonPartialsModule
    ],
    declarations: [
        YesNoComponent,
        GetTextDialogComponent,
        ShowTextDialogComponent,
        SendErrorDetailsDialogComponent,
        GetDualTextDialogComponent,
        SearchChemicalForSuppliersComponent,
        GetValueDialogComponent,
        ViewSupplierProfilePublicDialogComponent,
        ViewSupplierReportPublicDialogComponent,
        MultipleChoiceQuestionsDialogComponent
    ],
    exports: [
        YesNoComponent,
        GetTextDialogComponent,
        ShowTextDialogComponent,
        SendErrorDetailsDialogComponent,
        GetDualTextDialogComponent,
        SearchChemicalForSuppliersComponent,
        GetValueDialogComponent,
        ViewSupplierProfilePublicDialogComponent,
        ViewSupplierReportPublicDialogComponent

    ]
})
export class CommonDialogsModule{}