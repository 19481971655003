import { Injectable } from '@angular/core';

@Injectable()
export class TransferVariableService {

  transferdata: any;
  constructor() { }

  get data(): any {
    return this.transferdata;
  }

  set data(val: any) {
    this.transferdata = val;
  }

}