<h4 mat-dialog-title>{{"auth.disc.tnc" | translate}}</h4>
<mat-dialog-content>
    <h1 class="text-center">{{"auth.disc.1" | translate}}</h1>
    <p class="text-center">{{"auth.disc.2" | translate}}</p>
    <h2 class="mt-3">{{"auth.disc.3" | translate}}</h2>
    <p>{{"auth.disc.4" | translate}}</p>
    <p>{{"auth.disc.5" | translate}}</p>
    <h2>{{"auth.disc.6" | translate}}</h2>
    <p>{{"auth.disc.7" | translate}}</p>
    <h2>{{"auth.disc.8" | translate}}</h2>
    <p>{{"auth.disc.9" | translate}}</p>
    <h2>{{"auth.disc.10" | translate}}</h2>
    <p>{{"auth.disc.11" | translate}}</p>
    <h2>{{"auth.disc.12" | translate}}</h2>
    <p>{{"auth.disc.13" | translate}}</p>
    <h2>{{"auth.disc.14" | translate}}</h2>
    <p>{{"auth.disc.15" | translate}}</p>
    <h2>{{"auth.disc.16" | translate}}</h2>
    <p>{{"auth.disc.17" | translate}}</p>
    <h2>{{"auth.disc.18" | translate}}</h2>
    <p>{{"auth.disc.19" | translate}}</p>
    <h2>{{"auth.disc.20" | translate}}</h2>
    <p>{{"auth.disc.21" | translate}}</p>
    <p inViewport
    [inViewportOptions]="{ threshold: [0] }"
    (inViewportAction)="inViewportAction($event)">
        {{"auth.disc.22" | translate}}
    </p>
</mat-dialog-content>
<mat-dialog-actions matTooltip="{{!isFinalPage? ('auth.disc.finish-tnc' | translate) : ''}}">
    <button mat-button color="warn" mat-dialog-close [disabled]="!isFinalPage">{{"auth.disc.reject" | translate}}</button>
    <button mat-button color="primary" [mat-dialog-close]="true" [disabled]="!isFinalPage">{{"auth.disc.accept" | translate}}</button>
</mat-dialog-actions>