import { Injectable } from '@angular/core';
import { PartialObserver, Subject } from 'rxjs';
import { MarketplaceProfile } from 'src/app/app.models';
import { BackendService } from 'src/app/services/backend.service';

@Injectable({
  providedIn: 'root'
})
export class MarketplaceProfileService {

  private profile: Subject<MarketplaceProfile> = new Subject()
  private profileSnapshot: MarketplaceProfile
  private updateCount = 0
  constructor(
    private backend: BackendService
  ) { }

  refreshProfile(){
    this.backend.getSupplierProfile(true).then((profile: MarketplaceProfile) => {
      this.setProfile(profile["data"])
    })
  }

  setProfile(profile: MarketplaceProfile){
    profile["__updateCounter__"] = this.updateCount++
    this.profile.next(profile)
    this.profileSnapshot = profile
  }

  getProfile(): MarketplaceProfile{
    return this.profileSnapshot
  }

  subscribe(next?: (value: MarketplaceProfile) => void){
    return this.profile.asObservable().subscribe(next)
  }

}
