import { NgModule } from "@angular/core";
import { MaterialCommonModule } from "src/app/material-common.module";
import { InfoComponent } from "./info/info.component";
import { PurpleButtonComponent } from "./purple-button/purple-button.component";
import { CarouselComponent } from "./carousel/carousel.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { InfoCardComponent } from "./info-card/info-card.component";

@NgModule({
    imports: [
        MaterialCommonModule,
        NgbModule
    ],
    declarations: [
        InfoComponent,
        PurpleButtonComponent,
        CarouselComponent,
        InfoCardComponent
    ],
    exports: [
        InfoComponent,
        PurpleButtonComponent,
        CarouselComponent,
        InfoCardComponent
    ]
})
export class CommonPartialsModule{}