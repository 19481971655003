import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {

  isDialogMode = true

  constructor(
    private router: Router
  ) {
    this.isDialogMode = this.router.url!="/privacy-policy"
  }

  ngOnInit(): void {
  }

}
