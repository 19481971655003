import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { ToastrService } from 'ngx-toastr';
import { BackendService } from 'src/app/services/backend.service';
import { backend } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private cookieService: CookieService,
    private http: HttpClient,
    private toastr: ToastrService
  ) { }

  async isAuthenticated(authUserType: string) {
    if(this.cookieService.hasKey("token")){
      return await this.getUserType().then((userType: any) => {
        if(!userType)
          return null
        else{
          if(userType['userType'] == 'supplier' && authUserType!='marketplace')
            return null
          else{
            switch(authUserType){
              case "marketplace":
                if(userType['userType'] != 'supplier')
                  return "brand"
                else
                  return this.verifySupplierToken().then((result: any) => {
                    if(result) 
                      return "marketplace" 
                    else 
                      return null
                  })
              case "brand":
                if(userType['userType'] != 'supplier')
                  return this.verifyToken().then((result: any) => {
                    if(result) 
                      return "brand" 
                    else 
                      return null
                  })
                else
                  return "marketplace"
            }
            if(userType['userType'] == 'supplier')
              return this.verifySupplierToken().then()
            else
              return this.verifyToken()
          }
        }
      }).catch((err: any) => {
        return null
      })
    }
    else
      return null
  }

  async verifyToken() {
    this.http.post<any>(`${backend}log-authg`, {
      lt: "verifyToken",
      ht: true,
      utr: 'brand'
    })
    let token: any = this.cookieService.get("token")
    if (token) {
      return await this.http.post(`${backend}auth/verify-token`, {
        token: token
      }).toPromise().then((res: any) => {
        if (res["status"] != 200) {
          this.toastr.warning("Your session has expired. Please login again.")
          return null;
        }
        let now: any = new Date()
        let exp: any = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes() + 20, now.getSeconds());
        this.cookieService.put("token", token, {
          expires: exp,
          sameSite: "lax"
        });
        return token;
      }, async err => {
        this.cookieService.remove("token");
        return null;
      })
    }
    else {
      return null;
    }
  }

  async getUserType() {
    let token: any = this.cookieService.get("token")
    if (token) {
      return this.http.post(`${backend}get-user-type`, {
        token: this.cookieService.get("token")
      }).toPromise()
    }
    else {
      return null;
    }
  }

  async verifySupplierToken() {
    let token: any = this.cookieService.get("token")
    if (token) {
      return await this.http.post(`${backend}auth/supplier/verify-token`, {
        token: token
      }).toPromise().then((res: any) => {
        if (res["status"] != 200) {
          this.toastr.warning("Your session has expired. Please login again.")
          return null;
        }
        let now: any = new Date()
        let exp: any = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes() + 20, now.getSeconds());
        this.cookieService.put("token", token, {
          expires: exp,
          sameSite: "lax"
        });
        return token;
      }, err => {
        this.cookieService.remove("token");
        return null;
      })
    }
    else {
      return null;
    }
  }

}
