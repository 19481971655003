import { Component, NgZone, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { MayaValidators } from 'src/app/app.utils';
import { BackendService } from 'src/app/services/backend.service';

@Component({
  selector: 'app-forgot-password-dialog',
  templateUrl: './forgot-password-dialog.component.html',
  styleUrls: ['./forgot-password-dialog.component.scss']
})
export class ForgotPasswordDialogComponent implements OnInit {

  emailControl = new FormControl("", [Validators.required, MayaValidators.email, MayaValidators.workEmail])
  accountTypeControl = new FormControl("", Validators.required)
  otpSent = false
  otpControl = new FormControl("", [Validators.required, MayaValidators.number, Validators.min(1000), Validators.max(999999)])
  otpVerified = false
  newPasswordControl = new FormControl("", [Validators.required, MayaValidators.password])
  confirmPasswordControl = new FormControl("", [Validators.required])
  loading = false

  constructor(
    private backend: BackendService,
    private toastr: ToastrService,
    private ngZone: NgZone,
    private dialogRef: MatDialogRef<ForgotPasswordDialogComponent>
  ) { }

  ngOnInit(): void {
    this.newPasswordControl.valueChanges.subscribe(val => {
      if (val !== this.confirmPasswordControl.value) {
        this.confirmPasswordControl.setErrors({ mismatch: true })
      }
      else{
        this.confirmPasswordControl.setErrors(null)
      }
    })
    this.confirmPasswordControl.valueChanges.subscribe(val => {
      if (val !== this.newPasswordControl.value) {
        this.confirmPasswordControl.setErrors({ mismatch: true })
      }
      else{
        this.confirmPasswordControl.setErrors(null)
      }
    })
  }

  getOTPError(){
    return this.otpControl.errors.required? "Required" : 
      this.otpControl.errors.number? "Not a valid OTP" :
      this.otpControl.errors.min || this.otpControl.errors.max? "OTP should be 4-6 characters" : "" 
  }

  getNewPasswordError(){
    return this.newPasswordControl.errors.required? "Required" : this.newPasswordControl.errors.weak? "Password should have at least 8 characters, 1 uppercase, 1 lowercase, 1 number and 1 special character" : ""
  }

  getConfirmPasswordError(){
    return this.confirmPasswordControl.errors.required? "Required" : this.confirmPasswordControl.errors.mismatch? "Passwords do not match" : ""
  }

  requestOTP(){
    this.loading = true
    this.emailControl.disable()
    this.backend.sendOTP(this.emailControl.value, this.accountTypeControl.value == "marketplace").then(res => {
      this.toastr.success("OTP Sent")
      this.otpSent = true
      this.loading = false
      this.emailControl.enable()
    }, err => {
      if(err["status"]==404){
        this.toastr.error("User not found!")
      }
      else{
        this.toastr.error("An error occurred! Please try again later")
      }
      this.emailControl.enable()
      this.loading = false
    })    
  }

  verifyOTP(){
    this.loading = true
    this.otpControl.disable()
    this.backend.verifyOTP(this.emailControl.value, this.otpControl.value, this.accountTypeControl.value == "marketplace").then(res => {
      this.otpVerified = true
      this.loading = false
      this.otpControl.enable()
    }, err => {
      if(err["status"]==404){
        this.toastr.error("User not found!")
      }
      else if(err["status"]==401){
        this.toastr.error("Invalid OTP")
      }
      else{
        this.toastr.error("An error occurred! Please try again later")
      }
      this.otpControl.enable()
      this.loading = false
    })
  }

  changePassword(){
    this.loading = true
    this.newPasswordControl.disable()
    this.confirmPasswordControl.disable()
    this.backend.changePassword(this.emailControl.value, this.otpControl.value, this.newPasswordControl.value, this.accountTypeControl.value == "marketplace").then(res => {
      this.toastr.success("Password Changed!")
      this.loading = false
      this.closeDialog()
    }, err => {
      if(err["status"]==404){
        this.toastr.error("User not found!")
      }
      else if(err["status"]==401){
        this.toastr.error("Invalid OTP")
      }
      else if(err["status"]==400){
        this.toastr.error("New password cannot be same as the last one.")
      }
      else{
        this.toastr.error("An error occurred! Please try again later")
      }
      this.newPasswordControl.disable()
      this.confirmPasswordControl.disable()
      this.loading = false
    })
  }

  getEmailError(){
    return this.emailControl.errors.required? "Required" : this.emailControl.errors.email? "Please enter a valid email" : this.emailControl.errors.prohibited_domain? "Please enter your business/work email" : ""
  }

  closeDialog(val?: any){
    this.ngZone.run(() => {
      this.dialogRef.close(val)
    })
  }

}
