<div mat-dialog-title class="d-title">
    {{(newUser? "auth.signup" : "auth.login") | translate}}
    <div class="flex-grow"></div>
    <button mat-icon-button (click)="closeDialog()" color="warn"><mat-icon>close</mat-icon></button>
</div>
<mat-dialog-content class="col-12">
    <div class="col-12" *ngIf="newUser">
        <form [formGroup]="registerForm">
            <div class="input-field">
                <div class="input-wrapper">
                    <input class="input" matInput placeholder="{{'auth.register.FN' | translate}}"
                        formControlName="fullName" cdkFocusInitial>
                    <mat-icon class="input-error" color="warn" *ngIf="registerForm.get('fullName').errors?.required"
                        matTooltip="{{'auth.required' | translate}}">error</mat-icon>
                </div>
            </div>
            <div class="input-field">
                <div class="input-wrapper">
                    <input class="input" matInput placeholder="{{'auth.register.CN' | translate}}"
                        formControlName="companyName">
                    <mat-icon class="input-error" color="warn" *ngIf="registerForm.get('companyName').errors?.required"
                        matTooltip="{{'auth.required' | translate}}">error</mat-icon>
                </div>
            </div>
            <div class="input-field">
                <div class="input-wrapper">
                    <input class="input" matInput placeholder="{{'auth.register.BE' | translate}}"
                        formControlName="email">
                    <mat-icon class="input-error" color="warn" *ngIf="registerForm.get('email').errors"
                        [matTooltip]="getEmailError(registerForm.get('email'))">error</mat-icon>
                </div>
            </div>
            <div class="input-field">
                <div class="input-wrapper">
                    <input class="input" matInput placeholder="{{'auth.register.PW' | translate}}" type="password"
                        formControlName="password">
                    <mat-icon class="input-error" color="warn" *ngIf="registerForm.get('password').errors?.required"
                        matTooltip="{{'auth.required' | translate}}">error</mat-icon>
                </div>
            </div>
            <div class="input-field">
                <div class="input-wrapper register-as">
                    <mat-select class="input" [formControl]="loginOrRegisterAs"
                        placeholder="{{'auth.register.Ra' | translate}}">
                        <mat-option value="brand">
                            {{"auth.register.PD" | translate}}
                        </mat-option>
                        <mat-option value="marketplace">
                            {{"auth.register.IP" | translate}}
                        </mat-option>
                    </mat-select>
                    <mat-icon class="input-error" color="warn" *ngIf="loginOrRegisterAs.errors?.required"
                        matTooltip="{{'auth.required' | translate}}">error</mat-icon>
                </div>
            </div>
            <div class="checkbox-wrapper">
                <mat-checkbox class="col-12 checkbox" color="primary" formControlName="subscribeToNewsletter"
                    labelPosition="after">
                    {{"auth.register.Dy" | translate}}
                </mat-checkbox>
            </div>

            <div class="col-12 submit-wrapper">
                <button type="submit" class="p-submit"
                    [disabled]="registerForm.invalid || loginOrRegisterAs.invalid || disableButton"
                    (click)="register()">
                    {{"auth.register.Lt" | translate}}
                    <mat-spinner class="mx-2" diameter="24" *ngIf="disableButton"></mat-spinner></button>
            </div>
        </form>
        <div class="col-12 my-4 tnc-pp">
            {{"auth.register.Bs" | translate}}
            <span class="d-link" (click)="openTNC()">
                {{"auth.register.Tnc" | translate}}
            </span>
            {{"auth.register.and" | translate}}
            <span class="d-link" (click)="openPrivacyPolicy()">
                {{"auth.register.PP" | translate}}
            </span>.
        </div>
        <div class="col-12 my-4 registered">
            {{"auth.register.Ar" | translate}}
            <span class="d-link" (click)="openLogin()">
                {{"auth.register.Li" | translate}}
            </span>
        </div>
    </div>

    <div class="col-12" *ngIf="!newUser">
        <form [formGroup]="loginForm">
            <div class="input-field">
                <div class="input-wrapper">
                    <input class="input" matInput placeholder="{{'auth.loginform.BE' | translate}}"
                        formControlName="email" cdkFocusInitial>
                    <mat-icon class="input-error" color="warn" *ngIf="loginForm.get('email').errors"
                        [matTooltip]="getEmailError(loginForm.get('email'))">error</mat-icon>
                </div>
            </div>
            <div class="input-field">
                <div class="input-wrapper">
                    <input class="input" matInput placeholder="{{'auth.loginform.PW' | translate}}" type="password"
                        formControlName="password">
                    <mat-icon class="input-error" color="warn" *ngIf="loginForm.get('password').errors?.required"
                        matTooltip="{{'auth.required' | translate}}">error</mat-icon>
                </div>
            </div>
            <div class="input-field register-as-field">
                <div class="input-wrapper register-as">
                    <mat-select class="input" [formControl]="loginOrRegisterAs"
                        placeholder="{{'auth.loginform.La' | translate}}">
                        <mat-option value="brand">
                            {{"auth.loginform.PD" | translate}}
                        </mat-option>
                        <mat-option value="marketplace">
                            {{"auth.loginform.IP" | translate}}
                        </mat-option>
                    </mat-select>
                    <mat-icon class="input-error" color="warn" *ngIf="loginOrRegisterAs.errors?.required"
                        matTooltip="{{'auth.required' | translate}}">error</mat-icon>
                </div>
            </div>
            <div class="col-12 submit-wrapper">
                <button type="submit" class="p-submit"
                    [disabled]="loginForm.invalid || loginOrRegisterAs.invalid || disableButton" (click)="login()">
                    {{"auth.loginform.Lt" | translate}}
                    <mat-spinner class="mx-2" diameter="24" *ngIf="disableButton"></mat-spinner></button>
            </div>
        </form>
        <div class="col-12 my-4 forgot-password">
            <span class="d-link" (click)="openForgotPassword()">
                {{"auth.loginform.FP" | translate}}
            </span>
        </div>
        <div class="col-12 my-4 registered">
            {{"auth.loginform.Nr" | translate}}
            <span class="d-link" (click)="openRegister()">
                {{"auth.loginform.SU" | translate}}
            </span>
        </div>
    </div>

</mat-dialog-content>