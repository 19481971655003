import { A11yModule } from "@angular/cdk/a11y";
import { HttpClientModule } from "@angular/common/http";
import { NgModule, ErrorHandler } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { VgBufferingModule } from "@videogular/ngx-videogular/buffering";
import { VgControlsModule } from "@videogular/ngx-videogular/controls";
import { VgCoreModule } from "@videogular/ngx-videogular/core";
import { VgOverlayPlayModule } from "@videogular/ngx-videogular/overlay-play";
import { VgStreamingModule } from "@videogular/ngx-videogular/streaming";
import { AgGridModule } from "ag-grid-angular";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { CookieModule } from "ngx-cookie";
import { CarouselModule } from "ngx-owl-carousel-o";
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from "ngx-toastr";
import { AppComponent } from "./app.component";
import { MaterialCommonModule } from "./material-common.module";
import { GlobalErrorHandler } from "./services/global-error-handler.service";
import { TransferVariableService } from "./services/transfervariable.service";
import { CommonDialogsModule } from "./templates/dialogs/common-dialogs.module";
import { CommonPartialsModule } from "./templates/partials/common-partials.module";
import { MayaCommonUtilsModule } from "./utils/maya-common-utils.module";
import { AppRoutingModule } from "./app.routes";
import { RouterModule } from "@angular/router";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxSpinnerModule,
    ToastrModule.forRoot(),
    CookieModule.forRoot(),
    MaterialCommonModule,
    A11yModule,
    AgGridModule,
    CarouselModule,
    CKEditorModule,
    VgCoreModule, 
    VgControlsModule, 
    VgStreamingModule, 
    VgOverlayPlayModule, 
    VgBufferingModule,
    MayaCommonUtilsModule,
    CommonDialogsModule,
    CommonPartialsModule,
    NgbModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  })
  ],
  providers: [
    TransferVariableService,
    {provide: ErrorHandler, useClass: GlobalErrorHandler},
    provideHttpClient(withInterceptorsFromDi())
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}