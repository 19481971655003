<div mat-dialog-content class="row" style="margin: 16px;">
    <h1 class="col-12" style="text-align: center; font-size: xxx-large; font-weight: bolder; line-height: 1;">{{"pvc.title" | translate}}</h1>
    
    <p class="col-12" style="margin: 8px 16px;">
        {{"pvc.1" | translate}}
    </p>
    <p class="col-12" style="margin: 8px 16px;">
        {{"pvc.2" | translate}}
    </p>
    <p class="col-12" style="margin: 8px 16px;">
        <a href="www.productdevedge.com">www.productdevedge.com</a> {{"pvc.3" | translate}}
    </p>
    <h4 class="col-12" style="margin: 4px 8px; font-size: large; font-weight: bold;">{{"pvc.4" | translate}}
</h4>
    
    <p class="col-12" style="margin: 8px 16px;">
        {{"pvc.5" | translate}}
    </p>
    <ul class="col-12 row" style="margin: 8px 32px;">
        <li class="col-12">{{"pvc.6" | translate}}</li>
        <li class="col-12">{{"pvc.7" | translate}}</li>
        <li class="col-12">{{"pvc.8" | translate}}</li>
        <li class="col-12">{{"pvc.9" | translate}}</li>
        <li class="col-12">{{"pvc.10" | translate}}</li>
        <li class="col-12">{{"pvc.11" | translate}}</li>
        <li class="col-12">{{"pvc.12" | translate}}</li>
        <li class="col-12">{{"pvc.13" | translate}}</li>
    </ul>
    <p class="col-12" style="margin: 8px 16px;">{{"pvc.14" | translate}}</p>
    
    <h4 class="col-12" style="margin: 4px 8px; font-size: large; font-weight: bold;">{{"pvc.15" | translate}}
</h4>
    <p class="col-12" style="margin: 8px 8px;">
        {{"pvc.16" | translate}}
    </p>
    <p class="col-12" style="margin: 8px 8px;">
        {{"pvc.17" | translate}}
    </p>
    
    <h4 class="col-12" style="margin: 4px 8px; font-size: large; font-weight: bold;">{{"pvc.18" | translate}}
</h4>
    <p class="col-12" style="margin: 8px 16px;">{{"pvc.19" | translate}}</p>
    <ul class="col-12 row" style="margin: 8px 32px;">
        <li class="col-12">{{"pvc.19" | translate}}</li> 
        <li class="col-12">{{"pvc.20" | translate}}</li> 
        <li class="col-12">{{"pvc.21" | translate}}</li> 
        <li class="col-12">{{"pvc.22" | translate}}</li> 
    </ul>
    
    <h4 class="col-12" style="margin: 4px 8px; font-size: large; font-weight: bold;">{{"pvc.23" | translate}}
</h4>
    <p class="col-12" style="margin: 8px 8px;">
        {{"pvc.24" | translate}}
    </p>
    
    <p class="col-12" style="margin: 8px 8px;">
        {{"pvc.25" | translate}}
    </p>

    <h4 class="col-12" style="margin: 4px 8px; font-size: large; font-weight: bold;">{{"pvc.26" | translate}}
</h4>
    <p class="col-12" style="margin: 8px 8px;">
        {{"pvc.27" | translate}}
    </p>
    <p class="col-12" style="margin: 8px 8px;">
        {{"pvc.28" | translate}}
    </p>

    <h5 class="col-12" style="margin: 4px 8px; font-weight: bold;">{{"pvc.29" | translate}}
</h5>
    <p class="col-12" style="margin: 8px 8px;">
        {{"pvc.30" | translate}}
    </p>

    <h5 class="col-12" style="margin: 4px 8px; font-weight: bold;">{{"pvc.31" | translate}}
</h5>
    <p class="col-12" style="margin: 8px 8px;">
        {{"pvc.32" | translate}}
    </p>
    <p class="col-12" style="margin: 8px 8px;">
        {{"pvc.33" | translate}}
    </p>

    <h4 class="col-12" style="margin: 4px 8px; font-size: large; font-weight: bold;">{{"pvc.34" | translate}}
</h4>
    <p class="col-12" style="margin: 8px 8px;">
        {{"pvc.35" | translate}}
    </p>
    <p class="col-12" style="margin: 8px 8px;">
        {{"pvc.36" | translate}}

        {{"pvc.37" | translate}}
   
        {{"pvc.38" | translate}}

    </p>

    <h4 class="col-12" style="margin: 4px 8px; font-size: large; font-weight: bold;">{{"pvc.39" | translate}}
</h4>
    <p class="col-12" style="margin: 8px 8px;">
        {{"pvc.40" | translate}}
    </p>
    <p class="col-12" style="margin: 8px 8px; font-weight: bold;">{{"pvc.41" | translate}}
    </p>
    <p class="col-12" style="margin: 8px 8px; font-weight: bold;">
        {{"pvc.42" | translate}}
    </p>
    <p class="col-12" style="margin: 8px 8px; font-weight: bold;">
        {{"pvc.43" | translate}}
    </p>
    <p class="col-12" style="margin: 8px 8px; font-weight: bold;">
        {{"pvc.44" | translate}}
    </p>
    <h4 class="col-12" style="margin: 4px 8px; font-size: large; font-weight: bold;">
        {{"pvc.45" | translate}}
</h4>
    <p class="col-12" style="margin: 8px 8px;"> 
        {{"pvc.46" | translate}}
    </p>
    <p class="col-12" style="margin: 8px 8px;">
        {{"pvc.47" | translate}}
    </p>
    <p  class="col-12" style="margin: 8px 8px;">
        cloud.google.com (<a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>)
    </p>
    <p class="col-12" style="margin: 8px 8px;">
        Office365 (<a href="https://www.mailerlite.com/privacy-policy">{{"pvc.48" | translate}}</a>)
    </p>

    <h4 class="col-12" style="margin: 4px 8px; font-size: large; font-weight: bold;">
        {{"pvc.49" | translate}}
    
</h4>
    <p class="col-12" style="margin: 8px 8px;">
        {{"pvc.50" | translate}}
    </p>
    
    <h4 class="col-12" style="margin: 4px 8px; font-size: large; font-weight: bold;">
        {{"pvc.51" | translate}}
    
</h4>
    <p class="col-12" style="margin: 8px 8px;">
        {{"pvc.52" | translate}}
        <a href="mailto:info@productdevedge.com">info&#64;productdevedge.com</a>.
    </p>
    <h4 class="col-12" style="margin: 4px 8px; font-size: large; font-weight: bold;">
        {{"pvc.53" | translate}}
    
</h4>
    <p class="col-12" style="margin: 8px 8px;">
        {{"pvc.54-1" | translate}}<a href="https://www.productdevedge.com">www.productdevedge.com</a>{{"pvc.54-2" | translate}}
    </p>
    <p class="col-12" style="margin: 8px 8px;">
        {{"pvc.55-1" | translate}}<a href="mailto:info@productdevedge.com">info&#64;productdevedge.com</a>{{"pvc.55-2" | translate}}
        
    </p>
</div>
<mat-dialog-actions *ngIf="isDialogMode">
    <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>