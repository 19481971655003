import { Injectable } from '@angular/core';
import { PartialObserver, Subject } from 'rxjs';
import { UserActivePlan, UserProfile } from 'src/app/app.models';
import { BackendService } from 'src/app/services/backend.service';

@Injectable({
  providedIn: 'root'
})
export class BrandProfileService {

  private profile: Subject<UserProfile> = new Subject()
  private profileSnapshot: UserProfile
  private updateCount = 0
  private activePlan: UserActivePlan = null

  constructor(
    private backend: BackendService
  ) { }

  refreshProfile(): Promise<UserProfile>{
    return this.backend.getUserProfile(true, true).then((profile: {data: UserProfile, plan: UserActivePlan}) => {
      this.setActivePlan(profile["activePlan"])
      this.setProfile(profile["data"])
      return profile["data"]
    })
  }

  setProfile(profile: UserProfile){
    profile["__updateCounter__"] = this.updateCount++
    this.profile.next(profile)
    this.profileSnapshot = profile
  }

  getProfile(): UserProfile{
    return this.profileSnapshot
  }

  asObservable(){
    return this.profile.asObservable()
  }

  setActivePlan(activePlan: UserActivePlan) {
    this.activePlan = activePlan
  }

  getActivePlan(): UserActivePlan{
    return this.activePlan
  }

}
