import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TermsAndConditionsComponent } from '../terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from '../privacy-policy/privacy-policy.component';
import { BackendService } from 'src/app/services/backend.service';
import { CookieService } from 'ngx-cookie';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { controllers } from 'chart.js';
import { prohib_domains } from 'src/app/app.defaults';
import { ForgotPasswordDialogComponent } from './forgot-password-dialog/forgot-password-dialog.component';
import { RegisterDisclaimerDialogComponent } from './register-disclaimer-dialog/register-disclaimer-dialog.component';
import { LocaleService } from 'src/app/services/locale.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-auth-dialog',
  templateUrl: './auth-dialog.component.html',
  styleUrls: ['./auth-dialog.component.scss']
})
export class AuthDialogComponent implements OnInit {

  newUser = false

  registerForm = new FormGroup({
    fullName: new FormControl("", Validators.required),
    companyName: new FormControl("", Validators.required),
    email: new FormControl("", [Validators.required, Validators.email, this.isBusinessEmail]),
    password: new FormControl("", Validators.required),
    subscribeToNewsletter: new FormControl(false)
  })

  inputsChanged = {
    fullName: false,
    companyName: false,
    email: false,
    password: false
  }

  loginForm = new FormGroup({
    email: new FormControl("", [Validators.required, Validators.email, this.isBusinessEmail]),
    password: new FormControl("", Validators.required)
  })

  loginOrRegisterAs = new FormControl("", Validators.required)
  loading: boolean;

  disableButton = false
  translation = null

  constructor(
    private dialog: MatDialog,
    private backend: BackendService,
    private dialogRef: MatDialogRef<AuthDialogComponent>,
    private cookies: CookieService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    private locale: LocaleService,
    private translate: TranslateService,
    @Inject (MAT_DIALOG_DATA) 
    public data: {
      postLoginpath?: string,
      authType: "register" | "login",
      preferredUserType: "brand" | "marketplace"
    }
  ) { }

  ngOnInit(): void {
    if(this.data && this.data.authType == "register"){
      this.newUser = true
      this.dialogRef.disableClose = true
    }
    if(this.data && this.data.preferredUserType)
      this.loginOrRegisterAs.patchValue(this.data.preferredUserType)
    else if(localStorage.getItem("accountTypePreference"))
      this.loginOrRegisterAs.patchValue(localStorage.getItem("accountTypePreference"))
    this.translate.getTranslation(this.locale.getTranslateLanguage()).subscribe(translation => {
      this.translation = translation
    })
  }

  isBusinessEmail(value: AbstractControl): ValidationErrors{
    if(prohib_domains.some(domain => value.value.toLocaleLowerCase().includes(domain))){
      return {prohibited_domain: true}
    }
    return null
  }

  async register(){
    let form: any = this.registerForm.value
    form["userType"] = "user"
    this.registerForm.disable()
    this.loading = true
    this.disableButton = true
    form["country"] = this.locale.getCountryCodeFromRegion()
    
    this.dialog.open(RegisterDisclaimerDialogComponent, {
      width: '85%',
      height: 'auto'
    }).afterClosed().subscribe(proceed => {
      if(proceed){
        let promise: Promise<any>
        if(this.loginOrRegisterAs.value == "brand"){
          promise = this.backend.registerUser(form)
        }
        else{
          promise = this.backend.registerSupplier(form)
        }
        return promise.then((result: any) => {
          this.loginForm.patchValue({
            email: form.email,
            password: form.password
          })
          return this.login()
        }).catch((err: any) => {
          if(err.error.message){
            this.toastr.error(err.error.message)
          }
          else if(err.message){
            this.toastr.error(err.message)
          }else{
            this.toastr.error("An error occurred! Please try again later...")
          }
        }).finally(() => {
          this.registerForm.enable()
          this.locale.refresh()
          this.loading = false
          this.disableButton = false
        })
      }
      else{
        this.toastr.error("You must agree to the TNC in order to access the platform!")
        this.registerForm.enable()
        this.loading = false
        this.disableButton = false
      }
    })

  }

  async login(){
    this.loading = true
    this.disableButton = true
    this.loginForm.disable()
    let promise: Promise<any>
    if(this.loginOrRegisterAs.value == "brand"){
      promise = this.backend.signInUser(this.loginForm.value)
    }
    else{
      promise = this.backend.signInSupplier(this.loginForm.value)
    }
    return promise.then((result: any) => {
      if (result["status"] == 200) {
        let now: any = new Date()
        let exp: any = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes() + 20, now.getSeconds());
        this.cookies.put("token", result["data"]["token"], {
          expires: exp,
          sameSite: "strict"
        });
        this.toastr.success("Succesfully Logged In!");
        localStorage.setItem("accountTypePreference", this.loginOrRegisterAs.value)
        console.log(this.loginOrRegisterAs.value)
        if(this.data && this.data.postLoginpath)
          this.router.navigate([this.data.postLoginpath])
        else 
          this.router.navigate([this.loginOrRegisterAs.value])
        this.closeDialog(true)
      }
      else {
        this.toastr.error(result["message"]);
      }
    }).catch((err: any) => {
      console.log(err)
      if(err.error.message){
        this.toastr.error(err.error.message)
      }
      else if(err.message){
        this.toastr.error(err.message)
      }else{
        this.toastr.error("An error occurred! Please try again later...")
      }
    }).finally(() => {
      this.loginForm.enable()
      this.locale.refresh()
      this.loading = false
      this.disableButton = false
    })
  }

  closeDialog(val?: any){
    this.ngZone.run(() => {
      this.dialogRef.close(val)
    })
  }

  openTNC(){
    this.dialog.open(TermsAndConditionsComponent, {width: "65%", height: "auto"})
  }

  openPrivacyPolicy(){
    this.dialog.open(PrivacyPolicyComponent, {width: "65%", height: "auto"})
  }

  openLogin(){
    this.newUser = false
    this.registerForm.patchValue({
      fullName: "",
      companyName: "",
      email: "",
      password: "",
      subscribeToNewsletter: false
    })
    this.dialogRef.disableClose = false
  }

  openForgotPassword(){
    this.dialog.open(ForgotPasswordDialogComponent, {
      width: "60%",
      height: "auto"
    })
  }

  openRegister(){
    this.loginForm.patchValue({
      email: "",
      password: ""
    })
    this.newUser = true
    this.dialogRef.disableClose = true
  }

  getEmailError(control: AbstractControl){
    return control.errors['required']? 
        (this.translation && this.translation.auth? this.translation.auth["required"] : "Required")
      : control.errors['email']? 
        (this.translation && this.translation.auth? this.translation.auth["valid-email"] : "Please enter a valid email")
      : control.errors['prohibited_domain']? 
        (this.translation && this.translation.auth? this.translation.auth["work-email"] : "Please enter your business/work email")
      : ""
  }

}
