import { NgModule } from "@angular/core";
import { DateTimePipe } from "./date-time.pipe";
import { StringFromListPipe } from "./string-from-list.pipe";
import { TruncatePipe } from "./truncate.pipe";

@NgModule({
    declarations: [
        DateTimePipe,
        StringFromListPipe,
        TruncatePipe
    ],
    exports: [
        DateTimePipe,
        StringFromListPipe,
        TruncatePipe
    ]
})
export class MayaCommonUtilsModule { }
  