import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  isDialogMode = true

  constructor(
    private router: Router
  ) { 
    this.isDialogMode = this.router.url!="/privacy-policy"
  }

  ngOnInit(): void {
  }

}
